<template>
    <div>
        <semrush-loader/>
        <Loader :loading="this.loader.loading" :text="this.loader.text"/>

        <div class="is-flex ml-6">
          <h1 class="title m-5" style="margin-left: 250px !important;">
            {{$t('home.title')}}
          </h1>
        </div>

        <div class="box is-half p-6">
            <div class="center">
                <project-dialog-create
                        :is-active="this.activeDialog"
                        @handleDialogClose="this.closeDialog"
                />

                <project-dialog-update
                        :is-active="this.activeDialogUpdate"
                        :name="this.nameToUpdate"
                        :projectId="projectIdToUpdate"
                        :oldEmoji="emojiToUpdate"
                        @handleDialogClose="this.closeDialogUpdate"
                />
            </div>
            <br/>


            <div class="ml-6">
                <vs-row>
                    <vs-col w="4" vs-type="flex" vs-justify="center" vs-align="center">
                        <vs-card @click="activeDialog=!activeDialog">
                            <template #img>
                                <img src="../assets/card.png" alt="">
                            </template>
                        </vs-card>
                    </vs-col>
                    <vs-col w="4" v-for="project in projects" :key="project._id" vs-type="flex" vs-justify="center"
                            vs-align="center">
                        <vs-card @click="$router.push('/project/' + project._id)">
                            <template #title>
                                <b-taglist>
                                    <b-tag style="color: #ffffff; background-color: #EF476F"><i class='bx bx-search-alt'/>
                                        <span class="span">
                                    {{ $t('project.nb_search', {nb: project.search.length}) }}
                                  </span></b-tag>
                                </b-taglist>
                            </template>
                            <template #img>
                                <img src="../assets/headerCard3.png" alt="">
                            </template>
                            <template #text>
                                {{$t('project.in_project')}}
                            </template>
                            <template #interactions>
                                <vs-button class="btn-chat" shadow primary>
                                    {{project.emoji ? project.emoji : emoji[Math.floor(Math.random()*emoji.length)]}}
                                    {{project.name}}
                                </vs-button>
                                <b-tooltip label="Rename project & edit icon" style="color: #ffffff" position="is-top">
                                    <vs-button
                                            icon
                                            color="warning"
                                            @click.stop="changeName(project._id, project.name, project.emoji)"
                                    >
                                        <i class='bx bxs-edit-alt'/>
                                    </vs-button>
                                </b-tooltip>
                                <b-tooltip label="Delete search" style="color: #ffffff" position="is-top">
                                    <vs-button
                                            icon
                                            color="danger"
                                            @click.stop="deleteProject(project)"
                                    >
                                        <i class='bx bxs-trash'/>
                                    </vs-button>
                                </b-tooltip>
                            </template>
                        </vs-card>
                        <br/>
                    </vs-col>
                </vs-row>
            </div>


        </div>
    </div>

</template>

<script>
    import Loader from '../components/Loader';
    import ProjectDialogCreate from '../components/ProjectDialogCreate';
    import ProjectDialogUpdate from '../components/ProjectDialogUpdate';
    import {getAllUserProject} from "@/utils/project/getAllForUser";
    import {getUserInfo} from "@/utils/user/getUserInfo";
    import {deleteProject} from "@/utils/project/deleteProject";
    import {onboardingProcess} from "@/services/extensionMessaging";
    import SemrushLoader from "../components/SemrushLoader";
    import EventBus from "@/services/event-bus";

    export default {
        name: 'Home',
        components: {
            SemrushLoader,
            ProjectDialogCreate,
            ProjectDialogUpdate,
            Loader
        },
        data() {
            return {
                name: '',
                nameToUpdate: '',
                projectIdToUpdate: '',
                emojiToUpdate: '',
                projects: [],
                emoji: ['🐣', '🦀', '🦄', '🧞', '👻', '👾', '🦅', '🤖', '🧠', '🚀', '🦊', '🐰', '🐭', '🐱', '🐶', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵', '🐼', '🙊', '🐌', '🦋', '🕷️', '🦗', '🐝', '🐡', '🐢', '🐍', '🦎', '🦍', '🦧', '🦚', '🦜', '🦢', '🦩', '🦝', '🍀', '🍄', '🐚', '🌻', '🌸', '🌼', '🌺', '🔥', '🌈', '❄️', '☃️', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🧀', '🥑', '🍓', '🍇', '🍉', '🥝', '🍍', '🎂', '🍮', '🧁', '🍭', '🍧', '🍪', '🍩', '🍿', '🍻', '🍺', '🍷', '🍾', '🥃', '🧂', '⚽', '🏀', '🏈', '⚾', '🎾', '🏐', '🏉', '🥏', '🎱', '🪀', '🏓', '🎭', '🎨', '🎬', '🎧', '🎯', '🎲', '♟️'],
                activeDialog: false,
                activeDialogUpdate: false,
                loader: {
                    loading: true,
                    text: '',
                },
            }
        },
        beforeMount() {
            this.process();
            EventBus.$on('updateProject', this.handleUpdateProject);
        },
        methods: {
            /**
             * Start process, normal process OR onboarding process (first connexion)
             *
             */
            async process() {

                // Onboarding process
                if (this.$cookies.get('new_user') === 'true') {
                    console.log("new user == true")
                    this.loader.text = 'We recover your semrush projects'
                    await onboardingProcess(async () => {
                        this.loader.loading = false
                        if (this.$cookies.get('token')) {
                          await getAllUserProject()
                              .then(res => {
                                this.projects = res.data.projects ? res.data.projects.reverse() : []
                              })
                          if(this.projects.length === 1 ){
                            await this.$router.push('/project/' + this.projects[0]._id)
                          }

                        }
                        //this.activeDialog = true
                    });

                } else { // Normal process
                  this.loader.loading = false
                  //this.loader.text = 'We recover your semrush projects'

                  if (this.$cookies.get('token')) {
                    await this.handleUserAndProject()
                    this.loader.loading = false
                  }
                }
            },

            async handleUserAndProject(){
              await getUserInfo()
                  .then(res => {
                    window.localStorage.setItem("userId", res.data.user._id);
                    window.localStorage.setItem("name", res.data.user.name);
                    window.localStorage.setItem("email", res.data.user.email);
                    window.localStorage.setItem("semRushId", res.data.user.semRushId);
                  })
                  .catch(() => {
                    this.notificationError('Failed to retrieve user data')
                  })
                  .finally(() => {
                    getAllUserProject()
                        .then(res => {
                          this.projects = res.data.projects ? res.data.projects.reverse() : []
                          this.loader.loading = false
                        })
                        .catch(() => {
                          this.notificationError('Failed to retrieve user projects')
                          this.loader.loading = false
                        })
                  })
            },

            handleUpdateProject() {
                getAllUserProject()
                    .then(res => {
                        this.projects = res.data.projects ? res.data.projects.reverse() : []
                    })
            },

            changeName(id, name, emoji) {
                this.nameToUpdate = name;
                this.projectIdToUpdate = id;
                this.emojiToUpdate = emoji ? emoji :  this.emoji[Math.floor(Math.random()*this.emoji.length)];
                this.activeDialogUpdate = true;

            },
            deleteProject(project) {
                this.$buefy.dialog.confirm({
                    title: 'Deleting project',
                    message: 'Are you sure you want to <b>delete</b> your project?',
                    confirmText: 'Delete project',
                    type: 'is-danger',
                    iconPack: "fas",
                    icon: "exclamation-circle",
                    hasIcon: true,
                    onConfirm: () => {
                        deleteProject(project._id)
                            .then(() => {
                                this.notificationSuccess(`Project <b>${project.name}</b> deleted !`);
                                getAllUserProject().then(res => {
                                    console.log(res.data.projects);
                                    this.projects = res.data.projects ? res.data.projects.reverse() : []
                                }).catch(() => {
                                    this.notificationError("Failed to retrieve user's projects")
                                })
                            })
                            .catch(() => {
                                this.notificationError(`Failed to delete <b>${project.name}</b> project`)
                            })
                    }
                })
            },
            closeDialog() {
                this.activeDialog = false
            }, closeDialogUpdate() {
                this.activeDialogUpdate = false
            },
        }
    }
</script>
