<template>
<div>
  <vs-dialog class="dialog-create" blur v-model="active" @close="handleClose">
    <template #header>
      <h4 class="not-margin" v-html="$t('project.create.title')"></h4>
      <br/>
    </template>
    <div class="con-form">
      <a @click="activePicker=!activePicker">
        <h1 style="font-size: xx-large">{{emojiChoosed ? emojiChoosed : emoji[randomEmoji]}}</h1>
      </a>
      <vs-input
          :label-placeholder="$t('project.create.placeholder_name')"
          v-model="name"
      >
      </vs-input>
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button gradient block @click="createProject">{{$t('project.create.button')}}</vs-button>
      </div>
    </template>
  </vs-dialog>


  <emoji-picker
    @emojiChoosed="emojiChoosedOk"
    @handleDialogClose="this.closePickerDialog"
   :is-active-picker="this.activePicker"/>

</div>
</template>

<script>
import {createProject} from "@/utils/project/create";
import EventBus from '@/services/event-bus';
import EmojiPicker from "@/components/EmojiPicker";

export default {
  name: "ProjectDialogCreate",
  components: {
    EmojiPicker,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      active: false,
      activePicker:false,
      name: '',
      emojiChoosed: '',
      domainSelect: '',
      url: '',
      randomEmoji: '',
      project: {},
      emoji: ['🐣', '🦀', '🦄', '🧞', '👻', '👾', '🦅', '🤖', '🧠', '🚀', '🦊', '🐰', '🐭', '🐱', '🐶', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵', '🐼', '🙊', '🐌', '🦋', '🕷️', '🦗', '🐝', '🐡', '🐢', '🐍', '🦎', '🦍', '🦚', '🦜', '🦢', '🦝', '🍀', '🍄', '🐚', '🌻', '🌸', '🌼', '🌺', '🔥', '🌈', '❄️', '☃️', '🍟', '🍕', '🥪', '🥙', '🌮', '🧀', '🥑', '🍓', '🍇', '🍉', '🥝', '🍍', '🎂', '🍮', '🧁', '🍭', '🍧', '🍪', '🍩', '🍿', '🍻', '🍺', '🍷', '🍾', '🥃', '⚽', '🏀', '🏈', '⚾', '🎾', '🏐', '🏉', '🥏', '🎱', '🏓', '🎭', '🎨', '🎬', '🎧','🎯', '🎲', '♟️']
    }
  },

  created() {
    this.active = this.isActive;
    this.randomEmoji = Math.floor(Math.random()*this.emoji.length);
  },

  computed:{
    projectDomain: function (){
      if (this.domainSelect){
        return this.domainSelect
      } else {
        return '';
      }
    }
  },
  watch: {
    isActive(value){
      this.active = value
    },
    domainSelect(value){
      if (value) {
        this.name = value
      } else {
        this.name = ''
      }
    },
  },

  methods: {
    closePickerDialog(){
      this.activePicker = false;
    },
    emojiChoosedOk(value){
      console.log(value);
      this.emojiChoosed = value
    },
    handleClose() {
      this.$emit('handleDialogClose')
    },
    createProject() {
      if(!this.emojiChoosed){
        this.emojiChoosed = this.emoji[this.randomEmoji]
      }
      createProject(this.name, this.emojiChoosed)
          .then(res => {

          // Event listen in sidebar
          EventBus.$emit('createProject', res.data.project);

            if (this.projectDomain){
              this.active = false;
              this.project = res.data.project
            } else {
              this.$router.push({name: 'Project', params: {idProject: res.data.project._id}})
            }

            this.notificationSuccess(`Project created`)
          })
          .catch(() => {
            this.notificationError(`Failed to create <b>${this.name}</b> project`)
          });
    },
  }
}
</script>

<style lang="scss">

.dialog-create .con-form{
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  .vs-input-content,
  .vs-select-content{
    margin: 10px 0;
  }

  .vs-input,
  .vs-select-content {
    width: 300px;
    max-width: 100%;
  }
}

.vs-input__label {
  font-size: 0.8rem;
}

.vs-input--has-icon {
  padding-left: 38px;
}
</style>
