<template>
<div>
  <vs-dialog class="dialog-create" blur v-model="active" @close="handleClose">
    <template #header>
      <h4 class="not-margin">Choose a new icon for your <b>project</b> by clicking on your favorite</h4>
      <br/>
    </template>

    <div class="con-form">

      <div class="grid">
        <vs-row  justify="center">
          <vs-col :key="index" v-for="(icon,index) in emoji" w="1">
            <a @click="sendEmoji(index)"><h1 style="font-size: xx-large">{{icon}}</h1></a>
          </vs-col>
        </vs-row>
      </div>

    </div>

  </vs-dialog>

</div>
</template>

<script>

export default {
  name: "EmojiPicker",
  props: {
    isActivePicker: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data() {
    return {
      active: false,
      emoji : ['🐣', '🦀' , '🦄','🧞', '👻', '👾', '🦅', '🤖', '🧠' , '🚀', '🦊', '🐰', '🐭', '🐱', '🐶', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵', '🐼', '🙊', '🐌', '🦋', '🕷️', '🦗', '🐝', '🐡', '🐢', '🐍', '🦎', '🦍', '🦧', '🦚', '🦜', '🦢', '🦩', '🦝', '🍀', '🍄', '🐚', '🌻', '🌸', '🌼', '🌺', '🔥', '🌈', '❄️', '☃️', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🧀', '🥑', '🍓', '🍇', '🍉', '🥝', '🍍', '🎂', '🍮', '🧁', '🍭', '🍧', '🍪', '🍩', '🍿', '🍻', '🍺', '🍷', '🍾', '🥃', '⚽', '🏀','🏈', '⚾', '🎾', '🏐', '🏉', '🥏', '🎱', '🪀', '🏓', '🎭', '🎨', '🎬', '🎧','🎯', '🎲', '♟️'],
      emojiChoosed: ''
    }
  },

  created() {
    this.active = this.isActivePicker;

  },

  watch: {
    isActivePicker(value){
      this.active = value
    },
  },

  methods: {
    handleClose() {
      this.$emit('handleDialogClose');
      this.$emit('emoji')
    },
    sendEmoji(index){
      this.emojiChoosed=this.emoji[index];
      this.$emit('emojiChoosed', this.emojiChoosed);
      this.active = false
    }
  }
}
</script>

<style lang="scss">

.dialog-create .con-form{
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  .vs-input-content,
  .vs-select-content{
    margin: 10px 0;
  }

  .vs-input,
  .vs-select-content {
    width: 300px;
    max-width: 100%;
  }
}

.vs-input__label {
  font-size: 0.8rem;
}

.vs-input--has-icon {
  padding-left: 38px;
}
</style>
