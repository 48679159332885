<template>
<div>
  <vs-dialog class="dialog-create" blur v-model="active" @close="handleClose">
    <template #header>
      <h4 class="not-margin" v-html="$t('project.update.title', {name: name})"/>
      <br/>
    </template>
    <div class="con-form">
      <a @click="activePicker=!activePicker">
        <h1 style="font-size: xx-large">{{emojiChoosed ? emojiChoosed : oldEmoji}}</h1>
      </a>
      <vs-input
          :label-placeholder="$t('project.create.placeholder_name')"
          v-model="name"
      >
      </vs-input>
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button gradient block @click="updateProject">{{$t('project.update.button')}}</vs-button>
      </div>
    </template>
  </vs-dialog>


  <emoji-picker
    @emojiChoosed="emojiChoosedOk"
    @handleDialogClose="this.closePickerDialog"
   :is-active-picker="this.activePicker"/>

</div>
</template>

<script>
import {getUserInfo} from "@/utils/user/getUserInfo";
import {updateName} from "@/utils/project/updateNameProject";
import EventBus from '@/services/event-bus';
import EmojiPicker from "@/components/EmojiPicker";

export default {
  name: "ProjectDialogUpdate",
  components: {
    EmojiPicker,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    oldEmoji:String,
    name:String,
    projectId:String
  },
  data() {
    return {
      active: false,
      activePicker:false,
      emojiChoosed: '',
      randomEmoji: '',
      emoji : ['🐣', '🦀' , '🦄','🧞', '👻', '👾', '🦅', '🤖', '🧠' , '🚀', '🦊', '🐰', '🐭', '🐱', '🐶', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵', '🐼', '🙊', '🐌', '🦋', '🕷️', '🦗', '🐝', '🐡', '🐢', '🐍', '🦎', '🦍', '🦧', '🦚', '🦜', '🦢', '🦩', '🦝', '🍀', '🍄', '🐚', '🌻', '🌸', '🌼', '🌺', '🔥', '🌈', '❄️', '☃️', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🧀', '🥑', '🍓', '🍇', '🍉', '🥝', '🍍', '🎂', '🍮', '🧁', '🍭', '🍧', '🍪', '🍩', '🍿', '🍻', '🍺', '🍷', '🍾', '🥃', '⚽', '🏀','🏈', '⚾', '🎾', '🏐', '🏉', '🥏', '🎱', '🪀', '🏓', '🎭', '🎨', '🎬', '🎧','🎯', '🎲', '♟️']
    }
  },

  created() {
    this.active = this.isActive;
    this.randomEmoji = Math.floor(Math.random()*this.emoji.length);

    if (this.$cookies.get('new_user') === 'true') {
      window.addEventListener("goFetchFavoriteDomains", () => {
        getUserInfo().then(response => {
          this.favoriteDomains = response.data.user.favoriteDomains
        })
      });
    } else {
      getUserInfo().then(response => {
        this.favoriteDomains = response.data.user.favoriteDomains
      })
    }
  },

  computed:{
    projectDomain: function (){
      if (this.domainSelect){
        return this.domainSelect
      } else {
        return '';
      }
    }
  },
  watch: {
    isActive(value){
      this.active = value
    },
    domainSelect(value){
      if (value) {
        this.name = value
      } else {
        this.name = ''
      }
    },
  },

  methods: {
    closePickerDialog(){
      this.activePicker = false;
    },
    emojiChoosedOk(value){
      console.log(value);
      this.emojiChoosed = value
    },
    handleClose() {
      this.$emit('handleDialogClose')
    },
    updateProject() {
      if(!this.emojiChoosed){
        this.emojiChoosed = this.oldEmoji
      }
      updateName(this.projectId, this.name, this.emojiChoosed)
          .then(res => {

          // Event listen in sidebar
          EventBus.$emit('updateProject', res.data.project);

            this.notificationSuccess(`Project updated`);
            this.active =false
          })
          .catch(() => {
            this.notificationError(`Failed to update <b>${this.name}</b> project`)
          });
    },
  }
}
</script>

<style lang="scss">

.dialog-create .con-form{
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  .vs-input-content,
  .vs-select-content{
    margin: 10px 0;
  }

  .vs-input,
  .vs-select-content {
    width: 300px;
    max-width: 100%;
  }
}

.vs-input__label {
  font-size: 0.8rem;
}

.vs-input--has-icon {
  padding-left: 38px;
}
</style>
