const axios = require('axios')
//const {notLoggedInAlert} = require('../services/extensionMessaging')
console.log("wtf")
const axiosInterceptor = axios.create();
axiosInterceptor.interceptors.response.use(function (response) {
    console.log(response)
    return response
}, function (error) {
    console.log(error.response.data)
    if (error.response.status === 401 || error.response.status === 403) {
      console.log("401 or 403 detected");
      //@todo : show a page with "You're not connected" and a button to reload call the "notLoggedInAlert" which should solve the issue
    //  notLoggedInAlert().then()
    }
    return Promise.reject(error)
})

export default axiosInterceptor;