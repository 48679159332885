<template>
  <div class="loader-semrush">
    <vs-dialog width="500px" prevent-close not-close v-model="active">
      <template #header>
        <h4 class="not-margin">Welcome to <b>Semantik</b></h4>
      </template>
      <div class="con-content">
        <div ref="content"></div>
      </div>
      <template class="center grid" #footer>
        <vs-row style="text-align: center; text-align: -webkit-center; margin-bottom: 15px">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <vs-button
                href="https://www.semrush.com/login"
                size="l"
                blank
            >
              Log in to Semrush
            </vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" w="6">
            <vs-button @click="reload()">
              <i class='bx bx-refresh'></i>Reload extension
            </vs-button>
          </vs-col>
        </vs-row>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
import {reloadExtension} from "@/services/extensionMessaging";

export default {
  name: "SemrushLoader",
  computed: {
    active: function () {
      return this.$cookies.get('token') ? false : true
    }
  },
  mounted() {
    this.listenCookieToken()
  },
  methods: {
    /**
     * Custom listenner for token cookie
     * If token exist redirect to home, else infinite loading
     */
    listenCookieToken() {

      if (!this.$cookies.get('token')) {
        this.$vs.loading({
          target: this.$refs.content,
          type: 'corners',
          color: 'primary',
          text: "We are fetching your Semrush data... If it takes too long, please check that you're logged in to Semrush and reload the page."
        })

        // Listener ask cookie all 3s while is empty
        const cookieListener = setInterval(() => {

          if (this.$cookies.get('token')) {
            clearInterval(cookieListener)
            this.$cookies.set('tokenListened', true)
            this.$router.go()
          }
        }, 3000)
      }
    },
    reload(){
      reloadExtension()
    }
  }
}
</script>

<style lang="scss">
.loader-semrush {
  .vs-loading__load__text {
    color: $dark;
    margin-top: 25px;
    font-weight: normal;
    font-size: 0.9em;
  }

  .vs-loading__load__animation{
    height: 50px;
    width: 50px;
  }

  .con-content{
    height: 150px;
  }

  .footer-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-size: 0.9em;
  }
}

</style>
